import * as React from 'react';
import {
  capitalize,
  Grid,
  GridProps,
  Link,
  styled,
  Typography,
} from '@mui/material';
import MetricLabel from 'common/Metrics/Label';
import ModelStatusChip from 'common/ModelStatusChip';
import { File, Model as ModelIcon, Time } from 'icons/figma';
import { useDataSourceURLQuery } from 'src/api';
import { Model, RunnerMode } from 'src/api/types/Model';
import { getModelDataSource } from 'src/Console/Projects/Models/utils';
import { shorthandDistance } from 'utils/date';
import Formatters from 'utils/formatters';

type CommonMetricsTypes = GridProps & {
  model: Model;
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  '&:not(:last-child)': {
    marginRight: theme.spacing(12),
  },
  marginTop: theme.spacing(2),
}));

export const CommonMetrics = ({ model, ...props }: CommonMetricsTypes) => {
  const dataSource = model && getModelDataSource(model);
  const artifactName = Formatters.Project.artifactName(dataSource);
  const urlQuery = useDataSourceURLQuery(
    {
      projectGuid: String(model.project_guid),
      artifactKey: String(dataSource),
    },
    {
      skip:
        !model ||
        !dataSource ||
        model.runner_mode !== RunnerMode.CLOUD ||
        !model.project_guid,
    }
  );
  const isModelCompleted = model.status_history?.completed;

  return (
    <Grid container {...props}>
      <StyledGrid item>
        <MetricLabel label="Status">
          <ModelStatusChip status={model.status} />
        </MetricLabel>
      </StyledGrid>
      <StyledGrid item>
        <MetricLabel
          label="Model type"
          icon={<ModelIcon height={16} width={16} />}
        >
          <Typography variant="body1Medium">
            {capitalize(model?.model_type || '')} -{' '}
            {capitalize(model?.runner_mode || '')}
          </Typography>
        </MetricLabel>
      </StyledGrid>
      {model.status_history?.completed && (
        <StyledGrid item>
          <MetricLabel
            label="Training time"
            icon={<Time height={16} width={16} />}
          >
            <Typography variant="body1Medium">
              {shorthandDistance({
                start: model?.status_history?.active as string,
                end: model?.status_history?.completed,
                addSuffix: false,
              })}
            </Typography>
          </MetricLabel>
        </StyledGrid>
      )}

      <StyledGrid item>
        <MetricLabel label="Data source" icon={<File height={16} width={16} />}>
          {urlQuery.isSuccess && !isModelCompleted ? (
            <Typography
              component={Link}
              variant="body1Medium"
              href={urlQuery.data}
              sx={{
                color: 'text.primary',
              }}
            >
              {artifactName}
            </Typography>
          ) : (
            <Typography
              variant="body1Medium"
              sx={{
                color: 'text.primary',
              }}
            >
              {artifactName}
            </Typography>
          )}
        </MetricLabel>
      </StyledGrid>
    </Grid>
  );
};
