import { alpha, Components, iconButtonClasses, Theme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonOwnProps {
    /**
     * The variant to use.
     * @default 'text'
     */
    variant?: 'outlined' | 'contained' | 'text';
  }
  interface IconButtonPropsColorOverrides {
    // Don't disable color="inherit" as Alert uses it
    // We don't use color="default"
    // default: undefined;
  }
}

/**
 * Icon Button is a separate Button component used to wrap around svg icon
 * and provide onClick interaction to the user.
 *
 * Mui doesn't support by default but we've added support for different `variants` to better
 * support styling of our icon buttons that match our `Button` components.
 *
 * To avoid confusion when using IconButton it's defaults should match that of MuiButton
 *
 * Custom variants we support
 * - text (default)
 * - contained
 * - outlined
 *
 * MUI colors that we support
 * - primary
 * - secondary (default)
 * - success
 * - error
 * - info
 * - warning
 * - text @deprecated - Should be cleaned up when feature flag is cleaned up for buttons
 *   https://gretel.atlassian.net/browse/INT-2348
 */
const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    // set custom variant to 'text' by default
    variant: 'text',
    // MUI default color is 'default' we want to default to 'secondary'
    color: 'secondary',
    // disable any ripple effects from default MUI
    disableRipple: true,
    // set size to 'large'
    size: 'large',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      // Since we allow color='inherit' check before we try and use a palette color.
      const isPaletteColor =
        ownerState.color !== 'text' && ownerState.color !== 'inherit';
      return {
        ...theme.typography.button2,
        borderRadius: theme.shape.radii.medium,
        // Apply 'Focus-ring' to focused icon
        ':focus,.Mui-focusVisible': {
          zIndex: theme.zIndex.tooltip, // Elevate element to avoid box-shadow clipping caused by nearby elements.
          boxShadow: theme.palette.action.focusBoxShadow,
        },
        '& > *': {
          // prevents child elements from registering clicks rather than the button itself
          // to help with Google Analytics event delegation
          pointerEvents: 'none',
        },
        /* Contained Icon Button styling, specifying here instead of 'variants: []' so we can utilize ownerState
         * to consolidate color/backgroundColor definitions
         */
        // variant='contained' styling
        // TODO: we can remove "ownerState.color !== 'text'" once the feature flag is merged, since color="text" won't be an option anymore
        // https://gretel.atlassian.net/browse/INT-2348
        ...(ownerState &&
          ownerState.color &&
          ownerState.variant === 'contained' &&
          isPaletteColor && {
            color: theme.palette[ownerState.color].contrastText,
            backgroundColor: theme.palette[ownerState.color].main,
            ':hover': {
              backgroundColor: theme.palette[ownerState.color].dark,
              // For color='secondary' we need to lighten on hover instead
              ...(ownerState.color === 'secondary' && {
                backgroundColor: theme.palette.secondary.light,
              }),
            },
            [`&.${iconButtonClasses.disabled}`]: {
              color: theme.palette.action.disabled,
              backgroundColor: theme.palette.action.disabledBackground,
            },
          }),
        // variant='outlined' styling
        ...(ownerState &&
          ownerState.variant === 'outlined' &&
          ownerState.color &&
          isPaletteColor && {
            border: '1px solid',
            borderColor: theme.palette[ownerState.color].light,
            ':hover': {
              borderColor: theme.palette[ownerState.color].main,
              backgroundColor: alpha(
                theme.palette[ownerState.color].main,
                theme.palette.action.hoverOpacity
              ),
            },
            [`&.${iconButtonClasses.disabled}`]: {
              color: theme.palette.action.disabled,
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
          }),
        // variant='text' styling
        ...(ownerState &&
          ownerState.variant === 'text' &&
          ownerState.color &&
          isPaletteColor && {
            color: theme.palette[ownerState.color].main,
            ':hover': {
              backgroundColor: alpha(
                theme.palette[ownerState.color].main,
                theme.palette.action.hoverOpacity
              ),
            },
            [`&.${iconButtonClasses.disabled}`]: {
              color: theme.palette.action.disabled,
            },
          }),
        // https://gretel.atlassian.net/browse/INT-2348
        // TODO remove when we clean up the usage of color='text'
        ...(ownerState &&
          ownerState.color &&
          ownerState.color === 'text' && {
            color: theme.palette.secondary.main,
            ':hover': {
              backgroundColor: alpha(
                theme.palette.secondary.main,
                theme.palette.action.hoverOpacity
              ),
            },
            [`&.${iconButtonClasses.disabled}`]: {
              color: theme.palette.action.disabled,
            },
          }),
      };
    },
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(7),
      padding: theme.spacing(1, 2),
      '&>svg': {
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      },
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(8),
      padding: theme.spacing(1, 2),
      '&>svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(10),
      padding: theme.spacing(2, 3),
      '&>svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
  },
};

export default MuiIconButton;
