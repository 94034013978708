import { buttonClasses, Components, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonOwnProps {
    // should only be applied in case of an `href`
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#download
    download?: boolean | string;
  }
  interface ButtonPropsColorOverrides {
    // We don't use color="inherit", yeet from typescript
    inherit: undefined;
  }
}

/**
 * We utilize the default variants from MUI for our buttons:
 * - text
 * - contained (default)
 * - outlined
 *
 * The MUI colors that we support
 * - primary
 * - secondary (default)
 * - success
 * - error
 * - warning
 * - info
 */
const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    // DisableElevation: removes shadows
    disableElevation: true,
    // MUI default variant is 'text', ours is 'contained'
    variant: 'contained',
    color: 'secondary',
    // TODO: Update current "medium" buttons to "large", and current "small" buttons to "medium"
    // Following up on https://gretel.atlassian.net/browse/INT-2171.
    size: 'large',
  },
  styleOverrides: {
    /**
     * Root, common styles applied to all button variants and colors.
     * @param theme - theme object light/dark mode.
     */
    root: ({ theme }) => ({
      ...theme.typography.button2,
      textTransform: 'none',
      borderRadius: theme.shape.radii.medium,
      // prevents child elements from registering clicks rather than the button itself
      // to help with Google Analytics event delegation
      '& > *': {
        pointerEvents: 'none',
      },
      // Apply 'Focus-ring' to focused buttons
      ':focus-visible,&.Mui-focusVisible': {
        zIndex: theme.zIndex.tooltip, // Elevate element to avoid box-shadow clipping caused by nearby elements.
        boxShadow: theme.palette.action.focusBoxShadow,
      },
    }),
    /**
     * Styles applied for 'variant="contained"' buttons. Default variant applied to buttons.
     * @param ownerState - Allows for checking of other prop values set for the button, such as color.
     * @param theme - current theme object.
     */
    contained: ({ ownerState, theme }) => ({
      // Since color="secondary" is dark, on hover we want to use secondary.light instead of secondary.dark
      ...(ownerState.color === 'secondary' && {
        ':hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }),
    }),
    /**
     * Specific styling for an icon added to the button using `startIcon`
     */
    startIcon: {
      marginLeft: 0,
    },
    /**
     * Specific styling for an icon added to the button using `endIcon`
     */
    endIcon: {
      marginRight: 0,
    },
    /**
     * Specific styling for `size="small"`
     * Includes icon size styling
     * @param theme - current theme object.
     */
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(7),
      padding: theme.spacing(1, 3),
      [`.${buttonClasses.icon} svg`]: {
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      },
    }),
    /**
     * Specific styling for `size="medium"`. This is the default size
     * Includes icon size styling
     * @param theme - current theme object.
     */
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(8),
      padding: theme.spacing(1, 3),
      [`.${buttonClasses.icon} svg`]: {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    /**
     * Specific styling for `size="large"`
     * Includes icon size styling
     * @param theme - current theme object.
     */
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(10),
      padding: theme.spacing(2, 3),
      [`.${buttonClasses.icon} svg`]: {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
  },
};

export default MuiButton;
